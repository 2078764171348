<template>
  <div class="wrapp-account right-block-panel">
    <div class="right-block-content" v-if="currentUser">
      <div class="right-block-content__intestation">
        <span class="t-big">{{ $t('welcome') }} <b class="text-capitalize">{{ currentUser.firstname }}</b></span>
        <p class="top-message">{{ $t('account-intro') }}</p>
      </div>
      <div class="right-block-content__body">
        <ul class="nav flex-column pages-link-list">
          <li class="page-link-item" v-for="route in getAccountRoutes(this.$router.options.routes)" :key="route.path">
            <router-link :to="{ name: route.name }" v-html="$t('route.'+route.path)" class="bcm-link bcm-link-primary"></router-link>
          </li>
        </ul>
        <div class="logout-cta">
          <button class="btn btn-primary full-width" type="button" @click.prevent="logout">
            {{$t('logout')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    })
  },
  methods: {
    logout () {
      const _this = this
      this.$store.commit('ui/setActiveComponent', {})
      this.$store.dispatch('user/logout')
        .finally(() => {
          _this.$router.push({ name: 'home' })
        })
    },
    getAccountRoutes (routes) {
      let allAccRoute = []
      const loop = (routes) => {
        _.each(routes, (item) => {
          if (item.meta && item.meta.accountPage) {
            allAccRoute.push(item)
          }
          if (item.children) {
            loop(item.children)
          }
        })
      }
      loop(routes)
      return allAccRoute
    }
  }
}
</script>

<style lang="scss">
.right-block-panel {
  .right-block-content {
    &__body {
      margin-top: 40px;

      .pages-link-list {
        .page-link-item {
          margin-bottom: 15px;

          a {
            &::after {
              width: 0 !important;
            }

            &:hover,
            &.router-link-active {
              &::after {
                width: 100% !important;
              }
            }
          }

        }
      }

      .logout-cta {
        margin-top: 20px;
      }
    }
  }
}
</style>
